.app__write_story_page {
  /* background-color: #fdcd29; */
  background-color: #f9f9f9;
  border-radius: var(--app_border_radius);
  max-width: 1024px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.app__write_story_page h1,
.app__write_story_page h2 {
  background-color: #fdcd29;
  padding: 10px;
  border-radius: var(--app_border_radius);
}
.app__write_story_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.app__write_story_form .title {
  font-size: 24px;
  padding: 10px;
  border-radius: var(--app_border_radius);
  border: 1px solid #ccc;
  background-color: #fff;
  max-width: 560px;
  width: 100%;
  color: #333;
}

.app__write_story_form .title:focus {
  outline: none !important;
  border: 2px solid #333;
}

.app__write_story_form .title:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.app__write_story_rte {
  background-color: #fff;
}

.app__write_story_rte .ql-editor {
  min-height: 200px;
}

.app__write_story_rte,
.ql-container,
.ql-editor,
.ql-toolbar {
  border-radius: 10px;
}

.app__write_story_page .submit_btn {
  border-radius: var(--app_border_radius);
  background-color: #fdcd29;
  border: none;
  color: #333;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  font-weight: 600;
  width: 200px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 5px;
}
.app__write_story_page .submit_btn:hover {
  background-color: #333;
  color: #fdcd29;
}

.app__write_story_page .submit_btn:hover span {
  padding-right: 25px;
}

.app__write_story_page .submit_btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.app__write_story_page .submit_btn span::after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.app__write_story_page .submit_btn:hover span::after {
  opacity: 1;
  right: 0;
}
