.app__stories_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 756px;
  width: 100%;
}

/* STORY BLOCK */
.app__story_block {
  background-color: #f6f8fa;
  border: 1px solid #e9e9e9;
  min-height: 100px;
  padding: 10px;
  border-radius: var(--app_border_radius);
  text-decoration: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.3s ease-in-out;
}

.app__story_block:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fffbd8;
  transform: scale(1.05);
}

.app__story_block .story_title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #333;
}

.app__story_block .story_content {
  color: #333;
  font-size: 16px;
}

/* STORY BUTTONS */
.story__butons_holder {
  /* background-color: lightcoral; */
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
}

.story__butons_holder a {
  text-decoration: none;
}
.story__btn_left,
.story__btn_right {
  flex: 1;
  padding: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.story__btn_right {
  justify-content: flex-end;
}

.award_bronze:hover,
.award_bronze.active {
  color: #c77b30;
}
.award_silver:hover,
.award_silver.active {
  color: #a8a9ac;
}
.award_gold:hover,
.award_gold.active {
  color: #fdcd29;
}

.story_comment_btn,
.story_share_btn,
.story_report_btn,
.award {
  color: #bbb;
}

.story__btn:hover {
  transform: scale(1.3);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.story_report_btn:hover {
  color: rgb(236, 34, 34);
}
/* STORY BUTTONS END*/
.app__story_block .meta_holder {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.app__story_block .avatar {
  width: 25px;
  border-radius: 50%;
}

.app__story_block .meta_holder a {
  font-weight: 600;
  color: #333;
  font-size: 12px;
}

/* STORY BLOCK END */

/* STORY PAGE STYLING */
.app__story {
  max-width: 756px;
  width: 100%;
}

.app__story .story_title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #333;
}

.app__story .story_content {
  color: #333;
  font-size: 16px;
  margin-bottom: 20px;
}
/* STORY PAGE STYLING END */
