.app__report_form .submit_btn {
  border-radius: var(--app_border_radius);
  background-color: #fdcd29;
  border: none;
  color: #333;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  font-weight: 600;
  width: 200px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 5px;
}
.app__report_form .submit_btn:hover {
  background-color: #333;
  color: #fdcd29;
}

.app__report_form .submit_btn:hover span {
  padding-right: 25px;
}

.app__report_form .submit_btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.app__report_form .submit_btn span::after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.app__report_form .submit_btn:hover span::after {
  opacity: 1;
  right: 0;
}
