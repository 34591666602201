.app__story .meta_holder {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.app__story .avatar {
  width: 25px;
  border-radius: 50%;
}

.app__story .meta_holder a {
  font-weight: 600;
  color: #333;
  font-size: 12px;
}
