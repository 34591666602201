.app__theme_page {
  max-width: 756px;
  width: 100%;
}

.app__theme_page .app__theme_page_header {
  color: #333;
  margin-bottom: 20px;
  background-color: #fdcd29;
  border-radius: var(--app_border_radius);
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.app__theme_page .app__theme_page_header a {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: var(--app_border_radius);
  cursor: pointer;
}

.app__theme_page .app__theme_page_header a:hover {
  color: #fff;
  background-color: #333;
}
