.app__comment_inp_btn {
  width: 100%;
  border: #ccc solid 1px;
  border-radius: var(--app_border_radius);
  padding: 10px;
}

.comment-form {
  margin-bottom: 20px;
}

.comment-form .ql-editor {
  min-height: 150px;
  background-color: #fff;
}

.editor-container {
  position: relative;
}

.submit-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  /* padding: 10px; */
  /* border-top: 1px solid #ccc; */
  /* background-color: #f9f9f9; */
}

.submit-container button {
  padding: 10px 20px;
  border: none;
  background-color: #fdcd29;
  color: #333;
  border-radius: var(--app_border_radius);
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.submit-container button:hover {
  background-color: #333;
  color: #fff;
}

.submit-container .cancel-btn {
  background-color: #ccc;
  color: #333;
}

.comment-list {
  margin-top: 20px;
}

.comment {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.comment-content {
  margin-bottom: 5px;
}

.comment-author {
  font-size: 0.9em;
  color: #555;
}
.comment_reply_holder {
  display: flex;
  flex-direction: column;
}

.comment_reply_holder .buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.app__comment_reply_btn {
  font-weight: 700;
  color: #555;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.app__comment_reply_btn:hover {
  color: #fdcd29;
}

.app__comments .meta_holder {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.app__comments .avatar {
  width: 25px;
  border-radius: 50%;
}

.app__comments .meta_holder a {
  font-weight: 600;
  color: #333;
  font-size: 12px;
  cursor: pointer;
}

.report_btn {
  color: #bbb;
  cursor: pointer;
}

.report_btn:hover {
  color: rgb(236, 34, 34);
  /* transform: scale(1.3);
  transition: all 0.2s ease-in-out; */
}
