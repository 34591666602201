.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

/* header */

.desktop-header {
  position: sticky;
  top: 0;
  background-color: #333;
  color: white;
  /* padding: 10px 20px; */
  text-align: center;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  height: 125px;
}

.logo_holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 15px;
}

.logo_holder img {
  max-height: 80px;
}

.nav_holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav_holder nav {
  flex: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
  text-transform: uppercase;
}

.nav_holder a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
  font-weight: 600;
}

.nav_holder a:hover {
  color: #fdcd29;
}

.nav_holder div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* .header__auth_holder,
.header___user_holder {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
  font-weight: 600;
  cursor: pointer;
} */

.app__login_btn {
  background-color: #fdcd29;
  border-radius: var(--app_border_radius);
  padding: 6px 12px;
  color: #333 !important;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.app__login_btn:hover {
  background-color: #fff;
}

.app__register_btn,
.app__logout_btn {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
  font-weight: 600;
  cursor: pointer;
}

.app__register_btn:hover,
.app__logout_btn:hover {
  color: #fdcd29;
}

/* header end */
.content-wrapper {
  display: flex;
  flex: 1;
  /* padding: 20px; */
}

.main-content {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 20px;
  /* background-color: #f9f9f9; */
}

.sidebar {
  flex: 1;
  /* background-color: #e9e9e9; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar .advertisment_test {
  position: fixed;
  top: 150px;
  right: 50px;
  max-width: 200px; /* Set a maximum width */
  max-height: 200px; /* Set a maximum height */
}

.sidebar .advertisment_test img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}

.sidebar .left_sidebar_navigation {
  position: fixed;
  top: 125px;
  left: 0;
  /* background-color: #fdcd29; */
  height: 100%;
  width: 250px;
  padding: 10px;
  overflow: auto;
  border-right: 1px solid #f9f9f9;
}

.sidebar .left_sidebar_navigation .themes_holder {
  display: flex;
  flex-direction: column;
}

.sidebar .left_sidebar_navigation .themes_holder a {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
}
.sidebar .left_sidebar_navigation .themes_holder a:hover {
  color: #333;
  background-color: #fdcd29;
  border-radius: var(--app_border_radius);
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

/* SCROLLBAR STYLING */
.sidebar .left_sidebar_navigation::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.sidebar .left_sidebar_navigation:hover::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Customize the scrollbar track */
.sidebar .left_sidebar_navigation::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Customize the scrollbar thumb */
.sidebar .left_sidebar_navigation::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Customize the scrollbar thumb on hover */
.sidebar .left_sidebar_navigation::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.sidebar .left_sidebar_navigation {
  scrollbar-width: none; /* Hide scrollbar by default */
}

.sidebar .left_sidebar_navigation:hover {
  scrollbar-width: thin; /* Show thin scrollbar on hover */
  scrollbar-color: #888 #f1f1f1; /* Customize scrollbar colors */
}
/* SCROLLBAR STYLING END */

/* Responsive Design */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .sidebar {
    display: none;
  }

  .main-content {
    flex: 1;
    width: 100%;
  }
}
