:root {
  --app__bg: #f0e4d4;
  --app_primary_color: #fff;
  --app_secondary_color: #fff;
  --app_border_radius: 3px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(180, 35, 35, 0);
  /* background-color: #fff; */
}

/*Report modal start*/
.app__report_modal_window {
  background: rgba(253, 205, 41, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  top: 0;
}
/*Report modal end*/

/* Auth modal start */
.app__auth_modal_window {
  background: rgba(253, 205, 41, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  top: 0;
}

.app__auth_modal {
  background-color: #fff;
  padding: 15px;
  border-radius: var(--app_border_radius);
  display: flex;
  flex-direction: column;
  max-width: 560px;
  width: 100%;
}

.app__auth_modal .close__btn_holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.app__auth_modal .close__btn_holder svg {
  cursor: pointer;
  background-color: #ccc;
  padding: 2px;
  border-radius: 50%;
  font-size: 28px;
}

.app__auth_modal .close__btn_holder svg:hover {
  background-color: #fdcd29;
}

.app__auth_modal .auth__modal_form_holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app__auth_form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
}

.app__auth_form input {
  font-size: 20px;
  padding: 10px;
  border-radius: var(--app_border_radius);
  border: 1px solid #ccc;
  background-color: #fff;
  max-width: 560px;
  width: 100%;
  color: #333;
}

.app__auth_form input:focus {
  outline: none !important;
  border: 2px solid #fdcd29;
}

.app__auth_form button {
  border-radius: var(--app_border_radius);
  background-color: #fdcd29;
  border: none;
  color: #333;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  font-weight: 600;
  width: 100%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 5px;
}

.app__auth_form button:hover {
  background-color: #333;
  color: #fdcd29;
}

.auth__modal_form_holder .text {
  color: #333;
  font-weight: 500;
  margin-top: 15px;
}

.auth__modal_form_holder .switcher {
  font-weight: 600;
  cursor: pointer;
  color: #0056b3;
}
/* Auth modal end */
